import { css } from "@emotion/core"
import {
  Button,
  Container,
  Paper,
  Typography,
  useTheme,
  Grid,
} from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import { Link } from "gatsby"
import { OverPack } from "rc-scroll-anim"
import React, { useMemo } from "react"
import IconEnterprise from "../assets/svgs/icon_enterprise.svg"
import IconFree from "../assets/svgs/icon_free.svg"
import IconPro from "../assets/svgs/icon_pro.svg"
import Inline from "./Inline"
import Section from "./Section"
import Stack from "./Stack"

const PricingTableItem = React.forwardRef(
  (
    {
      title,
      subtitle,
      zIndex,
      elevation,
      infoList,
      colored,
      disabled,
      icon,
      price,
      buttonText,
      buttonVariant = "contained",
      href,
      to,
      priceSub,
    },
    ref
  ) => {
    const Icon = icon

    const bg = colored
      ? `${
          disabled
            ? "#eeeeee"
            : "linear-gradient(0deg, #8514C1 25%, #6111B1 100%)"
        }`
      : `${disabled ? "#eeeeee" : "white"}`

    const itemStyle = css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: ${zIndex};
      border-radius: 10px;
      width: 360px;
      height: 100%;
      //max-width: 33%;

      padding: 16px 48px 32px 48px;
      margin-bottom: 24px;
      background: ${bg};
    `
    const iconStyle = css`
      height: 110px;
      max-width: 80px;
      margin-bottom: 24px;
    `
    // const buttonProps = href ? { href } : { to }

    return (
      <Paper ref={ref} elevation={elevation} css={itemStyle}>
        <Stack
          css={css`
            width: 100%;
            color: inherit;
          `}
          align={"center"}
          space={30}
          dividers={true}
          colored={colored}
        >
          <div
            key={"Top"}
            css={css`
              color: ${colored ? "#fff" : "inherit"};
            `}
          >
            {icon && (
              <Icon
                css={[
                  css`
                    opacity: ${disabled ? 0.5 : 1};
                  `,
                  iconStyle,
                ]}
                className="PricingTableItem-image u-marginCenter"
                alt={title}
              />
            )}
            {/*<img src={null} css={css`width: 100px; height: 100px; background-color: #ccc`}></img>*/}
            <Typography
              variant={"h3"}
              css={css`
                padding-bottom: 16px;
              `}
            >
              {title}
            </Typography>
            <Typography
              variant={"subtitle1"}
              css={css`
                min-height: 56px;
                margin-bottom: 24px;
              `}
            >
              {subtitle}
            </Typography>
          </div>

          {infoList.map((info, index) => {
            return (
              <Inline
                key={index}
                space={8}
                css={css`
                  text-align: left;
                  width: 100%;
                  color: ${colored ? "#fff" : "inherit"};
                `}
              >
                {info.active ? (
                  <CheckCircleIcon color={colored ? "secondary" : "primary"} />
                ) : (
                  <RadioButtonUncheckedIcon
                    color={colored ? "secondary" : "primary"}
                  />
                )}
                <Typography align={"left"} variant={"body2"}>
                  {info.text}
                </Typography>
              </Inline>
            )
          })}
        </Stack>
        <div
          css={css`
            flex-grow: 1;
          `}
        />
        <Typography
          variant={"h4"}
          css={css`
            padding-top: 24px;
            color: ${colored ? "#fff" : "inherit"};
          `}
        >
          {price}
        </Typography>
        <Typography
          variant={"body2"}
          css={css`
            padding-bottom: 32px;
            padding-top: 4px;
            color: ${colored ? "#fff" : "inherit"};
          `}
        >
          {priceSub ? priceSub : "\u00A0"}
        </Typography>
        <Button
          component={href ? Button : Link}
          to={to ? to : null}
          href={href ? href : null}
          fullWidth
          size={"large"}
          disabled={disabled}
          color={colored ? "secondary" : "primary"}
          variant={buttonVariant}
        >
          {buttonText}
        </Button>
      </Paper>
    )
  }
)

const PricingTable = ({ id }) => {
  // const classes = useStyles()

  return (
    <Section>
      <Container id={id} maxWidth={"lg"}>
        <Typography
          variant={"h2"}
          css={css`
            padding-bottom: 64px;
          `}
        >
          MobileUI - Plans and Pricing
        </Typography>
        <Grid container justify="center" spacing={4}>
          <Grid item>
            <PricingTableItem
              key={0}
              title={"Starter"}
              subtitle={"For evaluation and personal projects"}
              zIndex={3}
              to="/signup/"
              elevation={20}
              buttonText={"Sign Up"}
              buttonVariant={"outlined"}
              icon={IconFree}
              price={"Free"}
              priceSub="No credit card required"
              infoList={[
                { active: true, text: "Full Java and Kotlin Support" },
                { active: true, text: "Cross-platform UI-System" },
                { active: true, text: "MobileUI Android Studio Plugin" },
                { active: true, text: "Complete Documentation" },
                { active: true, text: "Community Developer Support" },
                { active: false, text: "Commercial Use" },
              ]}
            />
          </Grid>
          <Grid item>
            <PricingTableItem
              key={1}
              title={"Purple"}
              subtitle={
                "Get all features and full productivity for your commercial apps."
              }
              zIndex={2}
              href="mailto:sales@mobileui.dev?subject=MobileUI%20Purple%20Licensing"
              colored
              elevation={20}
              buttonText={"Contact Us"}
              icon={IconPro}
              price={"Free Beta"}
              priceSub="Join our beta program today."
              infoList={[
                { active: true, text: "Everything from Starter" },
                { active: true, text: "Cross-platform Plugin System" },
                { active: true, text: "Commercial Plugin Collection" },
                {
                  active: true,
                  text: "Dependency Injection based on Micronaut Inject",
                },
                { active: false, text: "Commercial Use (coming soon)" },
                { active: false, text: "Royalty Free Apps (coming soon)" },
              ]}
            />
          </Grid>
          {/* <Grid item=6>
            <PricingTableItem
              key={2}
              title={"Black"}
              subtitle={
                "The choice for professional developers that need fast support and all features."
              }
              colored
              zIndex={1}
              href="mailto:sales@mobileui.dev"
              elevation={20}
              buttonText={"Contact Us"}
              icon={IconEnterprise}
              price={"135 €/month"}
              priceSub="Per developer with annual billing"
              infoList={[
                { active: true, text: "Everything from Purple" },
                { active: true, text: "Cross-platform Charts" },
                { active: true, text: "Cross-platform Maps" },
                { active: true, text: "Lottie Animations" },
                { active: true, text: "Android Vector Assets" },
                { active: true, text: "WebP Image Support" },
                { active: true, text: "Support via personal Chat" },
              ]}
            />
            </Grid> */}
          <Grid item>
            <PricingTableItem
              key={2}
              title={"Enterprise"}
              subtitle={
                "Get all features and the full support package for your dev-team."
              }
              zIndex={1}
              href="mailto:sales@mobileui.dev?subject=MobileUI%20Enterprise%20Licensing"
              elevation={20}
              buttonText={"Contact Us"}
              icon={IconEnterprise}
              price={"Let's talk!"}
              priceSub="We find the perfect solution for you."
              infoList={[
                { active: true, text: "Everything from Purple" },
                { active: true, text: "Mission Critical Support" },
                { active: true, text: "Source Code Insight" },
                { active: true, text: "Expert on Demand" },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default PricingTable
