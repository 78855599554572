import { css } from "@emotion/core"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import React from "react"
import Carousel from "../components/Carousel"
import FullscreenDemo from "../components/FullscreenDemo"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import LeftRight from "../components/LeftRight"
import PricingTable from "../components/PricingTable"
import Quote from "../components/Quote"
// import Testimonials from "../components/Testimonials"
import { useMediaQuery, useTheme } from "@material-ui/core"
import Seo from "../components/Seo"

export const query = graphql`
  query {
    imageLayout: file(relativePath: { eq: "OneLayout.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageCodebase: file(relativePath: { eq: "OneCodebase.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageJavaForum: file(relativePath: { eq: "JavaForum-Collage.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageStudiApp: file(relativePath: { eq: "StudiApp-Collage.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageAppraisalApp: file(
      relativePath: { eq: "TargensAppraisalApp-Collage.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageMobileUIGallery: file(
      relativePath: { eq: "MobileUI-Gallery-Collage.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  // const { loading, error, data } = useQuery(QUERY)
  // if (loading) return <p>Loading...</p>
  // if (error) return <p>Error : {error}</p>

  const carouselItems = [
    {
      image: data.imageMobileUIGallery,
      title: "MobileUI Gallery",
      subtitle:
        "Cross-platform showcase and reference for MobileUI widgets, layouts and components",
      appStoreLink: "https://apps.apple.com/app/id1509751429",
      googlePlayLink:
        "https://play.google.com/store/apps/details?id=io.nevernull.mobileui.gallery.android",
    },
    {
      image: data.imageJavaForum,
      title: "Java Forum Stuttgart",
      subtitle:
        "The official conference app for the Java Forum. Available for iOS and Android",
      appStoreLink:
        "https://apps.apple.com/de/app/java-forum-stuttgart/id1466975828",
      googlePlayLink:
        "https://play.google.com/store/apps/details?id=io.nevernull.javaforum.android&utm_source=MobileUI&utm_campaign=MobileUI",
    },

    {
      image: data.imageStudiApp,
      title: "Stuttgart StudiApp",
      subtitle:
        "Cultural guide for Stuttgart with program guide, ticket shop and time schedule. A full-stack Java implementation",
    },
    {
      image: data.imageAppraisalApp,
      title: "Targens Appraisal Data Center",
      subtitle:
        "Application for real estate experts. Built for Android phones and tablets.",
    },
  ]

  const theme = useTheme()
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Layout isFrontPage>
      <Seo title={"Home"}/>
      <Hero />
      <LeftRight
        reverse
        colored
        title={"Native Cross-platform User Interfaces"}
        image={data.imageLayout}
        actionTitle={"Learn more about the UI System"}
        actionLink={"https://docs.mobileui.dev/mobileui-layouts/"}
      >
        <Typography>
          MobileUI uses Android’s layout system to arrange platform-native
          widgets on the screen. That’s right! We have ported Android Layouts to
          iOS. And it runs blazing fast!
        </Typography>
        <Typography>
          Profit from a "write once run anywhere" solution — without making
          compromises regarding performance or user experience.
        </Typography>
      </LeftRight>
      <LeftRight
        title={"Java and Kotlin Codebase"}
        image={data.imageCodebase}
        actionTitle={"Learn more about Code Reuse"}
        actionLink={"https://docs.mobileui.dev/mobileui-unified-codebase/"}
      >
        <Typography color={"inherit"}>
          Build your apps with Java and Kotlin, reusing your favorite libraries,
          tools and techniques. Through ahead-of-time compilation, MobileUI apps
          run with bare metal performance. Your customers will love it.
        </Typography>
        <Typography color={"inherit"}>
          You are in full control: All native Android and iOS APIs are
          accessible through Java. Extend your app with platform-specific code
          without switching languages or tools.
        </Typography>
      </LeftRight>
      <div
        css={css`
          background: linear-gradient(
            180deg,
            #fafafa ${matchUpSm ? "32" : "30"}%,
            #8514c1 ${matchUpSm ? "32" : "30"}%,
            #6111b1 100%
          );
        `}
        // css={css`
        //   background: linear-gradient(
        //     180deg,
        //     #fafafa ${matchUpSm ? "19" : "15"}%,
        //     #8514c1 ${matchUpSm ? "19" : "15"}%,
        //     #6111b1 100%
        //   );
        // `}
      >
        <FullscreenDemo />
        {/*<Testimonials />*/}
      </div>
      <Carousel items={carouselItems} interval={3000} autoplay={false} />
      {/*<Roadmap/>*/}
      <PricingTable id={"PricingTable"} />
      <Quote />
      {/*/!*TODO: Attributions*!/*/}
      {/*/!* Google Play and the Google Play logo are trademarks of Google LLC.*!/*/}
      {/*/!* Astronaut, Java*!/*/}
    </Layout>
  )
}

export default IndexPage
